export default Page;

import React, { useRef } from "react";
import {
  ActionIcon,
  Anchor,
  Button,
  Container,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoad from "react-lazy-load-image-component";
import GuestLayout from "../../layout/Guest.Layout";
import AnimatedGroup from "../../components/AnimatedGroup";
import WhyChooseImgGen from "../../components/landing-page/WhyChooseImgGen";
import Showcase from "../../components/landing-page/Showcase";
import StartGeneratingToday from "../../components/landing-page/StartGeneratingToday";
import FrequentlyAskedQuestions from "../../components/landing-page/FrequentlyAskedQuestions";
const { LazyLoadImage } = LazyLoad;

import "@mantine/carousel/styles.css";
import "@mantine/core/styles/Title.css";
import "@mantine/core/styles/Text.css";
import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Input.css";
import "@mantine/core/styles/Loader.css";
import "@mantine/core/styles/ActionIcon.css";
import "@mantine/core/styles/Container.css";
import "@mantine/core/styles/SimpleGrid.css";
import "@mantine/core/styles/Image.css";

import { IconSparkles, IconWand } from "@tabler/icons-react";
import HowToGenerateImage from "../../components/landing-page/HowToGenerateImage";

import ResultImage1 from "../../static/results/2845be76-f157-40d5-83b4-7b9203bd15c1.webp";
import ResultImage2 from "../../static/results/2b6cb3ab-64ec-4b57-a2d6-56c3477cf587.webp";
import ResultImage3 from "../../static/results/6fb1c839-becc-4910-a3bb-c1d238a24240.webp";
import ResultImage4 from "../../static/results/774c945b-f027-4eed-9767-6dc4e4190719.webp";
import ResultImage6 from "../../static/results/WYKi57BVo13P-s.webp";
import ResultImage7 from "../../static/results/FEtqAfmPsJd-k.webp";
import ResultImage8 from "../../static/showcase/f6292294-1c9e-465b-8620-350604bea7ce.webp";

import Step1SVG from "../../static/icons/step1.webp";
import Step2SVG from "../../static/icons/step2.webp";
import Step3SVG from "../../static/icons/step3.webp";
import AnimatedCard from "../../components/AnimatedCard";
import { Carousel } from "@mantine/carousel";
import useGuestGenerateImage from "../../api/generate-image/useGuestGenerateImage";
import { useForm } from "@mantine/form";
import { navigate } from "vike/client/router";
import { yupResolver } from "mantine-form-yup-resolver";
import GenerateImageSchema from "../../models/schemas/GenerateImageSchema";
import { prompts } from "../../constants/Prompts";
import { notifications } from "@mantine/notifications";
import { useScrollIntoView } from "@mantine/hooks";
import TheUltimateImageEnhancementTools from "../../components/upscale-page/TheUltimateImageEnhancementTools";

function Page() {
  const { mutateAsync: generateImage } = useGuestGenerateImage();
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLFormElement>({
    offset: 60,
  });
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  const form = useForm({
    initialValues: {
      prompt: "",
    },
    validate: yupResolver(GenerateImageSchema),
  });

  const handleSubmit = async (values: any) => {
    try {
      const res = await generateImage(values);

      navigate(`/generate/${res.data.uuid}`);
    } catch (error) {
      notifications.show({
        title: (
          <Title component="div" size="small" fw={500} c="#000">
            Something went wrong
          </Title>
        ),
        message: "",
        color: "red",
        bg: "var(--mantine-color-red-1)",
      });
    }
  };

  const handleSurpise = async () => {
    form.setFieldValue(
      "prompt",
      prompts[Math.floor(Math.random() * prompts.length)]
    );
  };

  return (
    <GuestLayout>
      <AnimatedGroup>
        <Container w={{ base: "100%", md: "88%" }} size="xl" pt={60} pb={30}>
          <SimpleGrid
            cols={{
              base: 1,
              md: 2,
            }}
            spacing={{
              base: 10,
              sm: 80,
            }}
          >
            <Stack justify="center" align="center" pt={0} pb={60}>
              {/* <Container maw={840}> */}
              <Stack id="generate-image" justify="center" align="left">
                <Title
                  c="#FFF"
                  size="h1"
                  order={1}
                  tt="capitalize"
                  ta={{ base: "center", md: "left" }}
                >
                  {/* Generate your ideas into stunning visuals For Free */}
                  Free Online AI Image Generator (Text to Image)
                </Title>

                <Text
                  ta={{ base: "center", md: "left" }}
                  maw={{ base: "100%", md: "86%" }}
                >
                  Leverage our advanced AI to generate stunning high-resolution
                  images for you within seconds without watermark. It's
                  completely free and unlimited. No sign-up is required!
                </Text>
              </Stack>

              <Stack w="100%" align="center" mt={24}>
                <form
                  ref={targetRef}
                  style={{ width: "100%" }}
                  onSubmit={form.onSubmit(handleSubmit)}
                >
                  <Textarea
                    autosize
                    radius="xl"
                    maxRows={2}
                    size="xl"
                    w="100%"
                    fz="13px !important"
                    // maw="70%"
                    placeholder="What you want to see today?"
                    rightSectionWidth={62}
                    rightSection={
                      <ActionIcon
                        size={50}
                        radius="xl"
                        type="submit"
                        color="primary"
                        variant="filled"
                      >
                        <IconWand
                          style={{ width: rem(24), height: rem(24) }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    }
                    {...form.getInputProps("prompt")}
                  />

                  <Text ml={18} fz={13} mt={6}>
                    NSFW content is not allowed.
                  </Text>
                </form>

                <Group>
                  <Button
                    size="xs"
                    variant="subtle"
                    onClick={handleSurpise}
                    rightSection={<IconSparkles size={20} />}
                  >
                    Surpise Me
                  </Button>
                </Group>
              </Stack>

              <Group gap={6} justify="center" align="center" mt={16}>
                Want to generate Hi-Res images?{" "}
                <Anchor
                  c="primary"
                  href="/app/signup"
                  fw={700}
                  underline="hover"
                >
                  Sign up
                </Anchor>
              </Group>

              {/* </Container> */}
            </Stack>

            <Carousel
              loop
              withIndicators
              controlSize={40}
              height="100%"
              plugins={[autoplay.current]}
              onMouseEnter={autoplay.current.stop}
              onMouseLeave={autoplay.current.reset}
            >
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                  alt="Result Image 1"
                  height="100%"
                  src={ResultImage1}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 2"
                  height="100%"
                  src={ResultImage8}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 3"
                  height="100%"
                  src={ResultImage3}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 4"
                  height="100%"
                  src={ResultImage4}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 5"
                  height="100%"
                  src={ResultImage2}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 6"
                  height="100%"
                  src={ResultImage6}
                  width="600px"
                />
              </Carousel.Slide>
              <Carousel.Slide>
                <LazyLoadImage
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    objectFit: "cover",
                  }}
                  alt="Result Image 7"
                  height="100%"
                  src={ResultImage7}
                  width="600px"
                />
              </Carousel.Slide>
            </Carousel>
          </SimpleGrid>
        </Container>
      </AnimatedGroup>

      <AnimatedGroup>
        <HowToGenerateImage />
      </AnimatedGroup>

      <AnimatedGroup>
        <WhyChooseImgGen />
      </AnimatedGroup>

      <AnimatedGroup>
        <Group w="100%" justify="center" align="center" py={60}>
          <Container w="100%" className="section-container" size="xl">
            <AnimatedCard>
              <Stack justify="center" align="center" mt={0} w="100%">
                <SimpleGrid
                  w="100%"
                  cols={{ base: 1, sm: 2 }}
                  spacing={{ base: 10, sm: 40, md: 60, lg: 80 }}
                >
                  <Image w="100%" src={Step1SVG} mr="auto" />

                  <Stack>
                    <Title tt="capitalize" order={2} c="#FFF">
                      Free online Text to Image generator AI (Without Watermark)
                    </Title>

                    <Text>
                      Unleash your creativity with our free online AI image
                      creator powered by state-of-the-art AI technology. our AI
                      image generator works straight from your browser for
                      maximum convenience. Simply type in a text prompt and let
                      our advanced AI generate a stunning high resolution image
                      for you within seconds.
                    </Text>

                    <Button
                      mt={18}
                      c="black"
                      w="max-content"
                      fullWidth={false}
                      variant="white"
                      onClick={() =>
                        scrollIntoView({
                          alignment: "center",
                        })
                      }
                    >
                      Give a try
                    </Button>
                  </Stack>
                </SimpleGrid>
              </Stack>
            </AnimatedCard>
          </Container>
        </Group>
      </AnimatedGroup>

      <AnimatedGroup>
        <Group w="100%" justify="center" align="center" py={60}>
          <Container w="100%" className="section-container" size="xl">
            <AnimatedCard>
              <Stack justify="center" align="center" mt={0} w="100%">
                <SimpleGrid
                  w="100%"
                  cols={{ base: 1, sm: 2 }}
                  spacing={{ base: 10, sm: 40, md: 60, lg: 80 }}
                >
                  <Stack>
                    <Title c="white" order={2}>
                      Our Intuitive Interface Makes AI Image Creation Easy
                    </Title>

                    <Text>
                      ImgGen sets the new standard for intuitive AI image
                      generation with an interface so simple, you hardly need
                      instructions - just head to our homepage, type or paste
                      your desired text prompt into the provided text box and
                      hit "Generate Image". For creative inspiration, we provide
                      examples or you can come up with your own vision to bring
                      to life.
                    </Text>

                    <Button
                      mt={18}
                      c="black"
                      w="max-content"
                      fullWidth={false}
                      variant="white"
                      onClick={() =>
                        scrollIntoView({
                          alignment: "center",
                        })
                      }
                    >
                      Give a try
                    </Button>
                  </Stack>

                  <Image w="100%" src={Step2SVG} mr="auto" />
                </SimpleGrid>
              </Stack>
            </AnimatedCard>
          </Container>
        </Group>
      </AnimatedGroup>

      <AnimatedGroup>
        <Group w="100%" justify="center" align="center" py={60}>
          <Container w="100%" className="section-container" size="xl">
            <AnimatedCard>
              <Stack justify="center" align="center" mt={0} w="100%">
                <SimpleGrid
                  w="100%"
                  cols={{ base: 1, sm: 2 }}
                  spacing={{ base: 10, sm: 40, md: 60, lg: 80 }}
                >
                  <Image w="100%" src={Step3SVG} mr="auto" />

                  <Stack>
                    <Title c="white" order={2}>
                      Download Images Without Watermark For Free
                    </Title>

                    <Text>
                      Once your image has finished generating based on the
                      prompt you provided, you can download it right from our
                      site and use it on websites, social media platforms,
                      presentations, graphic designs and anywhere else you
                      desire.
                    </Text>

                    <Button
                      mt={18}
                      c="black"
                      w="max-content"
                      fullWidth={false}
                      variant="white"
                      onClick={() =>
                        scrollIntoView({
                          alignment: "center",
                        })
                      }
                    >
                      Give a try
                    </Button>
                  </Stack>
                </SimpleGrid>
              </Stack>
            </AnimatedCard>
          </Container>
        </Group>
      </AnimatedGroup>

      <AnimatedGroup>
        <Showcase />
      </AnimatedGroup>

      <AnimatedGroup>
        <StartGeneratingToday />
      </AnimatedGroup>

      <AnimatedGroup>
        <TheUltimateImageEnhancementTools />
      </AnimatedGroup>

      <AnimatedGroup>
        <FrequentlyAskedQuestions />
      </AnimatedGroup>
    </GuestLayout>
  );
}
